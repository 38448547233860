'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _base64Decode = require('./base64-decode');

Object.defineProperty(exports, 'OUTPUT_STRING', {
  enumerable: true,
  get: function get() {
    return _base64Decode.OUTPUT_STRING;
  }
});
Object.defineProperty(exports, 'OUTPUT_TYPED_ARRAY', {
  enumerable: true,
  get: function get() {
    return _base64Decode.OUTPUT_TYPED_ARRAY;
  }
});

var _base64Encode = require('./base64-encode');

Object.defineProperty(exports, 'encode', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_base64Encode).default;
  }
});
Object.defineProperty(exports, 'decode', {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_base64Decode).default;
  }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
