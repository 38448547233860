'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var OUTPUT_STRING = exports.OUTPUT_STRING = 'OUTPUT_STRING';
var OUTPUT_TYPED_ARRAY = exports.OUTPUT_TYPED_ARRAY = 'OUTPUT_TYPED_ARRAY';

var arr2str = function arr2str(arr) {
  return String.fromCharCode.apply(null, arr);
};

exports.default = function (base64Str) {
  var outputEncoding = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : OUTPUT_STRING;
  return outputEncoding === OUTPUT_STRING ? arr2str(decode(base64Str)) : decode(base64Str);
};

function decode(base64Str) {
  var iOut = 0;
  var arr = new Uint8Array(Math.ceil(base64Str.length * 3 / 4));

  for (var i = 0, len = base64Str.length, validBits = 0, bitsSoFar = 0; i < len; i++) {
    var bits = void 0;
    var c = base64Str.charCodeAt(i);
    if (c >= 0x41 && c <= 0x5a) {
      // [A-Z]
      bits = c - 0x41;
    } else if (c >= 0x61 && c <= 0x7a) {
      // [a-z]
      bits = c - 0x61 + 0x1a;
    } else if (c >= 0x30 && c <= 0x39) {
      // [0-9]
      bits = c - 0x30 + 0x34;
    } else if (c === 0x2b) {
      // +
      bits = 0x3e;
    } else if (c === 0x2f) {
      // /
      bits = 0x3f;
    } else if (c === 0x3d) {
      // =
      validBits = 0;
      continue;
    } else {
      // ignore all other characters!
      continue;
    }
    bitsSoFar = bitsSoFar << 6 | bits;
    validBits += 6;
    if (validBits >= 8) {
      validBits -= 8;
      arr[iOut++] = bitsSoFar >> validBits;
      if (validBits === 2) {
        bitsSoFar &= 0x03;
      } else if (validBits === 4) {
        bitsSoFar &= 0x0f;
      }
    }
  }

  return iOut < arr.length ? arr.subarray(0, iOut) : arr;
}
