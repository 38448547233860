'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = parse;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Parses structured e-mail addresses from an address field
 *
 * Example:
 *
 *    "Name <address@domain>"
 *
 * will be converted to
 *
 *     [{name: "Name", address: "address@domain"}]
 *
 * @param {String} str Address field
 * @return {Array} An array of address objects
 */
function parse(str) {
  var tokenizer = new Tokenizer(str);
  var tokens = tokenizer.tokenize();

  var addresses = [];
  var address = [];
  var parsedAddresses = [];

  tokens.forEach(function (token) {
    if (token.type === 'operator' && (token.value === ',' || token.value === ';')) {
      if (address.length) {
        addresses.push(address);
      }
      address = [];
    } else {
      address.push(token);
    }
  });

  if (address.length) {
    addresses.push(address);
  }

  addresses.forEach(function (address) {
    address = _handleAddress(address);
    if (address.length) {
      parsedAddresses = parsedAddresses.concat(address);
    }
  });

  return parsedAddresses;
};

/**
 * Converts tokens for a single address into an address object
 *
 * @param {Array} tokens Tokens object
 * @return {Object} Address object
 */
function _handleAddress(tokens) {
  var isGroup = false;
  var state = 'text';
  var address = void 0;
  var addresses = [];
  var data = {
    address: [],
    comment: [],
    group: [],
    text: []

    // Filter out <addresses>, (comments) and regular text
  };for (var i = 0, len = tokens.length; i < len; i++) {
    var token = tokens[i];

    if (token.type === 'operator') {
      switch (token.value) {
        case '<':
          state = 'address';
          break;
        case '(':
          state = 'comment';
          break;
        case ':':
          state = 'group';
          isGroup = true;
          break;
        default:
          state = 'text';
      }
    } else {
      if (token.value) {
        data[state].push(token.value);
      }
    }
  }

  // If there is no text but a comment, replace the two
  if (!data.text.length && data.comment.length) {
    data.text = data.comment;
    data.comment = [];
  }

  if (isGroup) {
    // http://tools.ietf.org/html/rfc2822#appendix-A.1.3
    data.text = data.text.join(' ');
    addresses.push({
      name: data.text || address && address.name,
      group: data.group.length ? parse(data.group.join(',')) : []
    });
  } else {
    // If no address was found, try to detect one from regular text
    if (!data.address.length && data.text.length) {
      for (var _i = data.text.length - 1; _i >= 0; _i--) {
        if (data.text[_i].match(/^[^@\s]+@[^@\s]+$/)) {
          data.address = data.text.splice(_i, 1);
          break;
        }
      }

      var _regexHandler = function _regexHandler(address) {
        if (!data.address.length) {
          data.address = [address.trim()];
          return ' ';
        } else {
          return address;
        }
      };

      // still no address
      if (!data.address.length) {
        for (var _i2 = data.text.length - 1; _i2 >= 0; _i2--) {
          data.text[_i2] = data.text[_i2].replace(/\s*\b[^@\s]+@[^@\s]+\b\s*/, _regexHandler).trim();
          if (data.address.length) {
            break;
          }
        }
      }
    }

    // If there's still is no text but a comment exixts, replace the two
    if (!data.text.length && data.comment.length) {
      data.text = data.comment;
      data.comment = [];
    }

    // Keep only the last address occurence, push others to regular text
    if (data.address.length > 1) {
      var _address = data.address.pop();
      data.text = data.text.concat(data.address.map(function (fakeAddress) {
        return '<' + fakeAddress + '>';
      }));
      data.address = [_address];
    }

    // Join values with spaces
    data.text = data.text.join(' ');
    data.address = data.address.join(' ');

    if (!data.address && isGroup) {
      return [];
    } else {
      address = {
        address: data.address || data.text || '',
        name: data.text || data.address || ''
      };

      if (address.address === address.name) {
        if ((address.address || '').match(/@/)) {
          address.name = '';
        } else {
          address.address = '';
        }
      }

      addresses.push(address);
    }
  }

  return addresses;
};

/*
 * Operator tokens and which tokens are expected to end the sequence
 */
var OPERATORS = {
  '"': '"',
  '(': ')',
  '<': '>',
  ',': '',
  // Groups are ended by semicolons
  ':': ';',
  // Semicolons are not a legal delimiter per the RFC2822 grammar other
  // than for terminating a group, but they are also not valid for any
  // other use in this context.  Given that some mail clients have
  // historically allowed the semicolon as a delimiter equivalent to the
  // comma in their UI, it makes sense to treat them the same as a comma
  // when used outside of a group.
  ';': ''

  /**
   * Creates a Tokenizer object for tokenizing address field strings
   *
   * @constructor
   * @param {String} str Address field string
   */
};
var Tokenizer = function () {
  function Tokenizer(str) {
    _classCallCheck(this, Tokenizer);

    this.str = (str || '').toString();
    this.operatorCurrent = '';
    this.operatorExpecting = '';
    this.node = null;
    this.escaped = false;
    this.list = [];
  }

  /**
   * Tokenizes the original input string
   *
   * @return {Array} An array of operator|text tokens
   */


  _createClass(Tokenizer, [{
    key: 'tokenize',
    value: function tokenize() {
      var chr = void 0;
      var list = [];
      for (var i = 0, len = this.str.length; i < len; i++) {
        chr = this.str.charAt(i);
        this.checkChar(chr);
      }

      this.list.forEach(function (node) {
        node.value = (node.value || '').toString().trim();
        if (node.value) {
          list.push(node);
        }
      });

      return list;
    }

    /**
     * Checks if a character is an operator or text and acts accordingly
     *
     * @param {String} chr Character from the address field
     */

  }, {
    key: 'checkChar',
    value: function checkChar(chr) {
      if ((chr in OPERATORS || chr === '\\') && this.escaped) {
        this.escaped = false;
      } else if (this.operatorExpecting && chr === this.operatorExpecting) {
        this.node = {
          type: 'operator',
          value: chr
        };
        this.list.push(this.node);
        this.node = null;
        this.operatorExpecting = '';
        this.escaped = false;
        return;
      } else if (!this.operatorExpecting && chr in OPERATORS) {
        this.node = {
          type: 'operator',
          value: chr
        };
        this.list.push(this.node);
        this.node = null;
        this.operatorExpecting = OPERATORS[chr];
        this.escaped = false;
        return;
      }

      if (!this.escaped && chr === '\\') {
        this.escaped = true;
        return;
      }

      if (!this.node) {
        this.node = {
          type: 'text',
          value: ''
        };
        this.list.push(this.node);
      }

      if (this.escaped && chr !== '\\') {
        this.node.value += '\\';
      }

      this.node.value += chr;
      this.escaped = false;
    }
  }]);

  return Tokenizer;
}();
