'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var LOOKUP = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.split('');
var MAX_CHUNK_LENGTH = 16383; // must be multiple of 3

var tripletToBase64 = function tripletToBase64(num) {
  return LOOKUP[num >> 18 & 0x3F] + LOOKUP[num >> 12 & 0x3F] + LOOKUP[num >> 6 & 0x3F] + LOOKUP[num & 0x3F];
};

function encodeChunk(uint8, start, end) {
  var output = '';
  for (var i = start; i < end; i += 3) {
    output += tripletToBase64((uint8[i] << 16) + (uint8[i + 1] << 8) + uint8[i + 2]);
  }
  return output;
}

var str2arr = function str2arr(str) {
  return new Uint8Array(str.split('').map(function (char) {
    return char.charCodeAt(0);
  }));
};

function encode(data) {
  var len = data.length;
  var extraBytes = len % 3; // if we have 1 byte left, pad 2 bytes
  var output = '';

  // go through the array every three bytes, we'll deal with trailing stuff later
  for (var i = 0, len2 = len - extraBytes; i < len2; i += MAX_CHUNK_LENGTH) {
    output += encodeChunk(data, i, i + MAX_CHUNK_LENGTH > len2 ? len2 : i + MAX_CHUNK_LENGTH);
  }

  // pad the end with zeros, but make sure to not forget the extra bytes
  if (extraBytes === 1) {
    var tmp = data[len - 1];
    output += LOOKUP[tmp >> 2];
    output += LOOKUP[tmp << 4 & 0x3F];
    output += '==';
  } else if (extraBytes === 2) {
    var _tmp = (data[len - 2] << 8) + data[len - 1];
    output += LOOKUP[_tmp >> 10];
    output += LOOKUP[_tmp >> 4 & 0x3F];
    output += LOOKUP[_tmp << 2 & 0x3F];
    output += '=';
  }

  return output;
}

exports.default = function (data) {
  return typeof data === 'string' ? encode(str2arr(data)) : encode(data);
};
