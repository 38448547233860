import { Component, NgModule, NgZone, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Broker } from '../../core/models/broker.model';
import { EmailRequest } from '../../core/models/emailrequest.model';
import { ParsedEmail } from '../../core/models/parsedemail.model';
import { SlimERPConnection } from '../../core/models/slimerpconnection.model';
import { ERPSubmissionAttempt } from '../../core/models/erpsubmissionattempt.model';
import { ERPConnectionCustomer } from '../../core/models/erpconnectioncustomer.model';
import { ERPConnectionContact } from '../../core/models/erpconnectioncontact.model';
import { ERPConnectionCustomerAddressBookRecord } from '../../core/models/erpconnectioncustomeraddressbookrecord.model';
import { ErpconnectioncustomerService } from '../../core/services/erpconnectioncustomer.service'; 
import { Order } from '../../core/models/order.model';
import { OrderItem } from '../../core/models/order.model';
import { User } from '../../core/models/user.model';
import { ExternalEmail } from '../../core/models/externalemail.model';
import { BrokerService } from '../../core/services/broker.service'; 
import { ErpconnectionService } from '../../core/services/erpconnection.service'; 
import { EmailrequestService } from '../../core/services/emailrequest.service'; 
import { ExternalemailService } from '../../core/services/externalemail.service';
import { ErpsubmissionattemptService } from '../../core/services/erpsubmissionattempt.service';
import { ParsedemailService } from '../../core/services/parsedemail.service'; 
import { UserService } from '../../core/services/user.service'; 
import { BlobService } from '../../core/services/blob.service'; 
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule, MatSelect } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { YesnoconfirmComponent } from '../../shared/components/yesnoconfirm/yesnoconfirm.component';
import { NaviconbarComponent } from '../../shared/components/naviconbar/naviconbar.component';
import { TruncatePipe } from '../../shared/pipes/truncate/truncate.pipe';
import parse from 'emailjs-mime-parser';
import * as MsgReader from '@sharpenednoodles/msg.reader-ts';
import { MSGFileData } from '@sharpenednoodles/msg.reader-ts';
import { Observable, of } from 'rxjs';

interface MimeNode {
	headers: {
		'content-type': {value: string}[]
	}
	charset?: string;
	content?: any;
	childNodes: MimeNode[];
}

@Component({
  selector: 'app-workflow',
  standalone: true,
  imports: [RouterModule, CommonModule, MatListModule, MatMenuModule, MatNativeDateModule, MatButtonToggleModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatTooltipModule, MatRadioModule, MatChipsModule, MatIconModule, MatSelectModule, MatButtonModule, MatBottomSheetModule, MatSidenavModule, MatTableModule, MatInputModule, FormsModule, NaviconbarComponent, TruncatePipe, DatePipe],
  templateUrl: './workflow.component.html',
  styleUrl: './workflow.component.scss'
})
export class WorkflowComponent {

	chosenFileName = "";
	chosenFile: File | null = null;
	currentExternalEmail: ExternalEmail | null = null;

	calculatedPCF: number = 0;

	PCFHeight: number = 0;
	PCFLength: number = 0;
	PCFWidth: number = 0;
	PCFWeight: number = 0;
	PCFPieceCount: number = 0;
	selectedPCFHeightUnit: string = "in";
	selectedPCFLengthUnit: string = "in";
	selectedPCFWidthUnit: string = "in";
	selectedPCFWeightUnit: string = "lb";

	mouseX: number = 0;
	mouseY: number = 0;

	showPCF: boolean = false;
	showDismissed: boolean = false;

	progress: number = null;

	isBrokerLoading: boolean = false;
	isEmailLoading: boolean = false;
	isExternalEmailLoading: boolean = false;
	isParsedLoading: boolean = false;
	isERPLoading: boolean = false;

	inboxMode: string = "turbotl";
	brokerForwardingEmail: string = "";

	isSavingNewEmail: boolean = false;

	flyoutmode: number = 0; // hide or show email creation or parsed email details.  0=new email, 1=parsed email

	selectedCustomer: number = -1;
	selectedOrderItem: OrderItem;
	selectedERPConnection: number = -1;
	selectedERPConnectionContact: number = -1;
	selectedERPConnectionCustomerAddressBookRecord: number = -1;
	txtSender: string = "";
	txtRecipient: string = "";
	txtSubject: string = "";
	txtBody: string = "";

	public list: any[] = [];

	brokerDataSource: Broker[] = [];
	userDataSource: User[] = [];
	erpConnectionDataSource: SlimERPConnection[] = [];

	emailRequestDataSource: EmailRequest[] = [];
	parsedEmailDataSource: ParsedEmail[] = [];
	erpSubmissionAttemptDataSource: ERPSubmissionAttempt[] = [];
	externalEmailDataSource: ExternalEmail[] = [];

	// this is both the "raw" unfiltered data set and the filtered one
	erpConnectionCustomerDataSource: ERPConnectionCustomer[] = [];
	erpConnectionCustomerDataSourceFiltered: ERPConnectionCustomer[] = [];
	erpConnectionCustomerSearchTerm: string = "";


	erpConnectionContactDataSource: ERPConnectionContact[] = [];
	erpConnectionCustomerAddressBookDataSource: ERPConnectionCustomerAddressBookRecord[] = [];
	erpConnectionCustomerAddressBookDataSourceFiltered: ERPConnectionCustomerAddressBookRecord[] = [];
	addressBookSearchTerm: string = "";

	searchSelection: number = 1;
	selectedBroker: number = 0;
	selectedUser: string = '';

	erpConnectionBrokerId: number = 0;

	selectedParsedEmail: ParsedEmail = this.makeParsedEmailObj();
	selectedParsedEmailDetailed: ParsedEmail = this.makeParsedEmailObj();
	selectedEmailRequest: EmailRequest = this.makeEmailRequestObj();
	selectedExternalEmail: ExternalEmail = this.makeExternalEmailObj();

	@ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
	@ViewChild('shipperAddressBook') shipperAddressBook!: MatSelect;
	@ViewChild('consigneeAddressBook') consigneeAddressBook!: MatSelect;

	constructor(private parsedEmailDataService: ParsedemailService,
	private brokerDataService: BrokerService,
	private erpConnectionCustomerDataService: ErpconnectioncustomerService,
	private erpSubmissionAttemptDataService: ErpsubmissionattemptService,
	private emailRequestDataService: EmailrequestService,
	private externalEmailDataService: ExternalemailService,
	private userDataService: UserService,
	private blobService: BlobService,
	private ngZone: NgZone,
	private erpConnectionDataService: ErpconnectionService,
	private _snackBar: MatSnackBar,
	private yesnoConfirm: MatBottomSheet)
	{
		this.isBrokerLoading = true;
		this.brokerDataService.getBrokers().subscribe({
			next: x => 
			{
				this.brokerDataSource = x;
				// if there's only one broker, just select it automatically
				if(this.brokerDataSource.length == 1)
				{
					this.selectedBroker = this.brokerDataSource[0].id;
					this.brokerForwardingEmail = this.brokerDataSource[0].forwardingEmail;
					this.refreshItems();
				}
				this.isBrokerLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Broker data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Broker data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Broker data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Broker data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Broker data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.userDataService.getUsers().subscribe({
			next: x => 
			{
				this.userDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching User data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching User data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching User data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching User data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching User data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

	}

	resetCustomerFilter()
	{
		this.erpConnectionCustomerSearchTerm = "";
		this.erpConnectionCustomerDataSourceFiltered = this.erpConnectionCustomerDataSource;
	}

	resetAddressBookFilter()
	{
		this.addressBookSearchTerm = "";
		this.erpConnectionCustomerAddressBookDataSourceFiltered = this.erpConnectionCustomerAddressBookDataSource;
	}

	// Filter options based on search term
	filterCustomers() {
		
		const searchTermLower = this.erpConnectionCustomerSearchTerm.toLowerCase();
		this.erpConnectionCustomerDataSourceFiltered = this.erpConnectionCustomerDataSource.filter(item =>
			item.name.toLowerCase().includes(searchTermLower));
	}

	// Filter options based on search term
	filterAddressBook() {
		
		const searchTermLower = this.addressBookSearchTerm.toLowerCase();
		this.erpConnectionCustomerAddressBookDataSourceFiltered = this.erpConnectionCustomerAddressBookDataSource.filter(item =>
			item.companyName.toLowerCase().includes(searchTermLower));
	}

	importConsigneeName()
	{
		this.addressBookSearchTerm = this.selectedParsedEmailDetailed.orderObject.consigneeBusinessName;
		this.consigneeAddressBook.open();
	}

	importShipperName()
	{
		this.addressBookSearchTerm = this.selectedParsedEmailDetailed.orderObject.shipperBusinessName;
		this.shipperAddressBook.open();
	}

	// Reset search term and filtered options when dropdown opens
	onCustomerDropdownOpen(isOpen: boolean) {
		if (isOpen) {
			this.erpConnectionCustomerSearchTerm = '';
			this.erpConnectionCustomerDataSourceFiltered = this.erpConnectionCustomerDataSource;
			// Set focus on the search input
			setTimeout(() => {
				this.searchInput.nativeElement.focus();
			});
		}
	}

	onFileSelected(event: Event): void {
		const input = event.target as HTMLInputElement;
		if (input?.files?.length) {
			this.chosenFileName = input.files[0].name;
			this.chosenFile = input.files[0];
		}
	}

	onIncludeDismissedChange(event: any)
	{
		this.showDismissed = event.selected;
	}

	openSnackBar(message: string, action: string)
	{
		this._snackBar.open(message, action,
		{
			horizontalPosition: 'center',
			verticalPosition: 'top',
			duration: 5000
		});
	}

	inboxModeChange()
	{
		this.refreshItems();
	}

	refreshExternalEmailsByBrokerId(brokerId: number)
	{
		this.isExternalEmailLoading = true;
		this.externalEmailDataService.getExternalEmailsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.isExternalEmailLoading = false;
				this.externalEmailDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isExternalEmailLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching External Email data by Broker: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching External Email data by Broker: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching External Email data by Broker: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching External Email data by Broker: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching External Email data by Broker: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	refreshExternalEmailsByUserId(userId: string)
	{
		this.isExternalEmailLoading = true;
		this.externalEmailDataService.getExternalEmailsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				this.isExternalEmailLoading = false;
				this.externalEmailDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isExternalEmailLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching External Email data by User: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching External Email data by User: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching External Email data by User: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching External Email data by User: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching External Email data by User: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}


	refreshEmailRequestsByBrokerId(brokerId: number)
	{
		this.isEmailLoading = true;
		this.emailRequestDataService.getEmailRequestsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.emailRequestDataSource = x;
				this.parsedEmailDataSource = [];
				this.erpSubmissionAttemptDataSource = [];
				this.isEmailLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isEmailLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data by Broker: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data by Broker: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data by Broker: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data by Broker: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data by Broker: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.erpConnectionDataService.getSlimERPConnectionsForBrokerId(brokerId).subscribe({
			next: x => 
			{
				this.erpConnectionDataSource = x;
				this.resetCustomerFilter();
				this.resetAddressBookFilter();
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.erpConnectionCustomerDataService.getERPConnectionCustomersByBrokerId(brokerId).subscribe({
			next: x => 
			{
				this.erpConnectionCustomerDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection Customer data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection Customer data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection Customer data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection Customer data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection Customer data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});



	}

	refreshEmailRequestsByUserId(userId: string)
	{
		this.isEmailLoading = true;
		this.emailRequestDataService.getEmailRequestsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				this.emailRequestDataSource = x;
				this.parsedEmailDataSource = [];
				this.erpSubmissionAttemptDataSource = [];
				this.isEmailLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isEmailLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data by User: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data by User: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data by User: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data by User: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data by User: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	refreshItems()
	{
		// this is the Broker selection
		if(this.searchSelection == 1 && this.inboxMode == "turbotl")
		{
			this.refreshEmailRequestsByBrokerId(this.selectedBroker);
		}
		// this is the User selection
		else if(this.searchSelection == 2 && this.inboxMode == "turbotl")
		{
			this.refreshEmailRequestsByUserId(this.selectedUser);
		}
		else if(this.searchSelection == 1 && this.inboxMode == "inbox")
		{
			this.refreshExternalEmailsByBrokerId(this.selectedBroker);
		}
		else if(this.searchSelection == 2 && this.inboxMode == "inbox")
		{
			this.refreshExternalEmailsByUserId(this.userDataSource.find(x => x.user_id == this.selectedUser).name);
		}
	}

	refreshItemsByBrokerId(brokerId: number)
	{
		this.isParsedLoading = true;
		this.parsedEmailDataService.getParsedEmailsForBrokerId(brokerId, this.showDismissed).subscribe({
			next: x => 
			{
				this.isParsedLoading = false;
				//this.parsedEmailDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isParsedLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data by Broker: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data by Broker: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data by Broker: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data by Broker: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data by Broker: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

		this.erpConnectionDataService.getSlimERPConnectionsForBrokerId(brokerId).subscribe({
			next: x => 
			{
				this.erpConnectionDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	refreshItemsByUserId(userId: string)
	{
		this.isParsedLoading = true;
		this.parsedEmailDataService.getParsedEmailsForUserId(userId, this.showDismissed).subscribe({
			next: x => 
			{
				//this.dataSource = x;
				this.isParsedLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isParsedLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Email Request data by User: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Email Request data by User: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Email Request data by User: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Email Request data by User: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Email Request data by User: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}


	showParsedEmailDetail(drawer: any, parsedEmail: ParsedEmail)
	{
		this.selectedOrderItem = null;
		this.refreshParsedEmailDetail(parsedEmail.id);
		this.flyoutmode = 1;
		drawer.open();
	}

	refreshAddressBook(erpConnectionCustomerId: number)
	{
		this.erpConnectionCustomerAddressBookDataSource = [];

		this.erpConnectionCustomerDataService.refreshAddressBook(erpConnectionCustomerId).subscribe({
			next: y =>
			{
				this.erpConnectionCustomerAddressBookDataSource = y;
				this.erpConnectionCustomerAddressBookDataSourceFiltered = y;
				this.openSnackBar("Successfully refreshed address book!", "Dismiss");
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Address Book data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Address Book data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Address Book data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Address Book data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Address Book data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});


	}

	refreshParsedEmailDetail(id: number)
	{
		this.erpConnectionContactDataSource = [];
		this.erpConnectionCustomerAddressBookDataSource = [];
		this.erpConnectionCustomerAddressBookDataSourceFiltered = [];
		this.parsedEmailDataService.getParsedEmail(id).subscribe({
			next: x =>
			{
				this.selectedParsedEmailDetailed = x;
				console.log(x);

// we can reinsert this if we actually want to grab contact information in the future.  we probably want address book instead.
/*
				this.erpConnectionCustomerDataService.getERPConnectionContactsByERPConnectionCustomerId(x.erpConnectionCustomerId).subscribe({
					next: y =>
					{
						this.erpConnectionContactDataSource = y;
						console.log("DONE!");
						console.log(y);
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error fetching Customer Contacts data: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error fetching Customer Contacts data: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error fetching Customer Contacts data: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error fetching Customer Contacts data: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error fetching Customer Contacts data: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});
*/
				this.erpConnectionCustomerDataService.getAddressBookByERPConnectionCustomerId(x.erpConnectionCustomerId).subscribe({
					next: y =>
					{
						this.erpConnectionCustomerAddressBookDataSource = y;
						this.erpConnectionCustomerAddressBookDataSourceFiltered = y;
						console.log(y);
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error fetching Address Book data: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error fetching Address Book data: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error fetching Address Book data: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error fetching Address Book data: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error fetching Address Book data: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});

			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Parsed Email data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Parsed Email data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Parsed Email data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Parsed Email data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Parsed Email data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});

	}

	populateShipperContactInformation()
	{
		let contact = this.erpConnectionCustomerAddressBookDataSource.filter(x => x.id == this.selectedERPConnectionCustomerAddressBookRecord)[0];

		this.selectedParsedEmailDetailed.orderObject.shipperBusinessName = contact.companyName;
		this.selectedParsedEmailDetailed.orderObject.shipperAddress = contact.address1;
		if(contact.address2 != "")
		{
			this.selectedParsedEmailDetailed.orderObject.shipperAddress += ", " + contact.address2;
		}
		this.selectedParsedEmailDetailed.orderObject.shipperCity = contact.city;
		this.selectedParsedEmailDetailed.orderObject.shipperState = contact.state;
		this.selectedParsedEmailDetailed.orderObject.shipperZip = contact.zip;
		this.selectedParsedEmailDetailed.orderObject.shipperPhone = contact.phone;
		this.selectedParsedEmailDetailed.orderObject.shipperName = contact.contactName;
		this.selectedParsedEmailDetailed.orderObject.shipperEmail = contact.email;

		if(contact.openTime != null)
		{
			this.selectedParsedEmailDetailed.orderObject.readyTime = contact.openTime;
		}
		if(contact.closeTime != null)
		{
			this.selectedParsedEmailDetailed.orderObject.closeTime = contact.closeTime;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isPickupLiftgate == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isPickupLiftgate = contact.isPickupLiftgate;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isPickupResidential == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isPickupResidential = contact.isPickupResidential;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isPickupLimitedAccess == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isPickupLimitedAccess = contact.isPickupLimitedAccess;	
		}

	}

	populateConsigneeContactInformation()
	{
		let contact = this.erpConnectionCustomerAddressBookDataSource.filter(x => x.id == this.selectedERPConnectionCustomerAddressBookRecord)[0];

		this.selectedParsedEmailDetailed.orderObject.consigneeBusinessName = contact.companyName;
		this.selectedParsedEmailDetailed.orderObject.consigneeAddress = contact.address1;
		if(contact.address2 != "")
		{
			this.selectedParsedEmailDetailed.orderObject.consigneeAddress += ", " + contact.address2;
		}
		this.selectedParsedEmailDetailed.orderObject.consigneeCity = contact.city;
		this.selectedParsedEmailDetailed.orderObject.consigneeState = contact.state;
		this.selectedParsedEmailDetailed.orderObject.consigneeZip = contact.zip;
		this.selectedParsedEmailDetailed.orderObject.consigneePhone = contact.phone;
		this.selectedParsedEmailDetailed.orderObject.consigneeName = contact.contactName;
		this.selectedParsedEmailDetailed.orderObject.consigneeEmail = contact.email;

		if(contact.openTime != null)
		{
			this.selectedParsedEmailDetailed.orderObject.receivingReadyTime = contact.openTime;
		}
		if(contact.closeTime != null)
		{
			this.selectedParsedEmailDetailed.orderObject.receivingCloseTime = contact.closeTime;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isDeliveryLiftgate == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isDeliveryLiftgate = contact.isDeliveryLiftgate;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isDeliveryResidential == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isDeliveryResidential = contact.isDeliveryResidential;
		}

		if(this.selectedParsedEmailDetailed.orderObject.isDeliveryLimitedAccess == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isDeliveryLimitedAccess = contact.isDeliveryLimitedAccess;	
		}

		if(this.selectedParsedEmailDetailed.orderObject.isDeliveryAppointment == null)
		{
			this.selectedParsedEmailDetailed.orderObject.isDeliveryAppointment = contact.isDeliveryAppointment;	
		}

	}

	updateERPConnection()
	{
		this.erpConnectionCustomerDataService.getERPConnectionCustomersByERPConnectionId(this.selectedERPConnection).subscribe({
			next: x => 
			{
				this.erpConnectionCustomerDataSource = x;
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Connection Customer data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Connection Customer data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Connection Customer data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Connection Customer data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Connection Customer data: Unknown Error", "Dismiss");
							break;
					}
				}
				console.log(err);
			}
		});


	}

	deleteItem(item: EmailRequest)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
			}
			else
			{
				// no, do not delete
			}
		});
	}

	createDerivedParsedEmail(drawer: any)
	{
		this.closeClicked(drawer);

		// we need to make sure that the TimeSpan value is in the format hh:mm:ss or else the API will not parse it correctly.
		// it may already be in that format, so we need to use Regex to format correctly.

		try
		{
			const timeRegex = /^(\d{2}):(\d{2})$/;
			const readyMatch = this.selectedParsedEmailDetailed.orderObject.readyTime.match(timeRegex);
			const closeMatch = this.selectedParsedEmailDetailed.orderObject.closeTime.match(timeRegex);

			if(readyMatch) {
				this.selectedParsedEmailDetailed.orderObject.readyTime += ":00";
			}

			if(closeMatch) {	
				this.selectedParsedEmailDetailed.orderObject.closeTime += ":00";
			}

		}
		catch(error)
		{
			console.log(error);
		}

		console.log(this.selectedParsedEmailDetailed.orderObject);
		this.parsedEmailDataService.createDerivedParsedEmail(this.selectedParsedEmailDetailed.id, this.selectedParsedEmailDetailed.orderObject).subscribe({
			next: x =>
			{
				this.openSnackBar("New Parsed Email created with desired updates.  Id: " + x.id, "Dismiss");
				this.refreshParsedEmailsForEmailRequestId(x.emailRequestId);
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating Parsed Email: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating Parsed Email: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating Parsed Email: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating Parsed Email: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating Parsed Email: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});

	}

	closeClicked(drawer: any)
	{
		this.resetCustomerFilter();
		this.resetAddressBookFilter();
		this.selectedOrderItem = null;
		this.selectedCustomer = -1;
		this.showPCF = false;
		this.chosenFileName = "";
		this.chosenFile = null;
		this.currentExternalEmail = null;
		drawer.close();	
	}

	openAttachment(drawer: any)
	{
		this.blobService.downloadBlobForEmailRequest(this.selectedParsedEmailDetailed.emailRequestId).subscribe({
			next: (response) => {

				// Extract the file name from the Content-Disposition header
				const contentDisposition = response.headers.get('content-disposition');
				let fileName = 'downloaded-file';

				if (contentDisposition) {
					// Prioritize filename* if present, else use filename
					const filenameRegex = /filename\*?=(?:(?:UTF-8'')?([^;]+)|"([^"]+)")/;
					const matches = filenameRegex.exec(contentDisposition);

					if (matches) {
					  // Choose whichever match is not undefined (i.e., prioritize filename*)
					  fileName = decodeURIComponent(matches[1] || matches[2]);
					}
				}

				const blob = response.body;
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.target = '_blank';
				a.rel = 'noopener';
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			},
			error: (error) => {
				this.openSnackBar("Error downloading file.", "Dismiss");
			}
		});


	}

	drawerClose(drawer: any)
	{
		this.selectedOrderItem = null;
		this.selectedCustomer = -1;
		this.showPCF = false;
		this.chosenFileName = "";
		this.chosenFile = null;
	}

	refreshERPSubmissionAttemptsForParsedEmailId(id: number)
	{
		this.isERPLoading = true;
		this.erpSubmissionAttemptDataService.getERPSubmissionAttemptsForParsedEmailId(id).subscribe({
			next: x =>
			{
				this.erpSubmissionAttemptDataSource = x;
				this.isERPLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{
				this.isERPLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching ERP Submission Attempt data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching ERP Submission Attempt data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	refreshParsedEmailsForEmailRequestId(id: number)
	{
		this.isParsedLoading = true;
		this.parsedEmailDataService.getParsedEmailsForEmailRequestId(id).subscribe({
			next: x =>
			{
				this.parsedEmailDataSource = x;
				this.isParsedLoading = false;
			},
			error: (err: HttpErrorResponse) =>
			{	
				this.isParsedLoading = false;
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error fetching Parsed Email data: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error fetching Parsed Email data: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error fetching Parsed Email data: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error fetching Parsed Email data: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error fetching Parsed Email data: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	externalEmailClick(externalEmail: ExternalEmail)
	{
		this.selectedExternalEmail = externalEmail;
	}

	emailRequestClick(emailRequest: EmailRequest)
	{
		this.selectedEmailRequest = emailRequest;
		if(this.selectedEmailRequest.brokerId != this.erpConnectionBrokerId)
		{
			this.erpConnectionDataService.getERPConnectionsForBrokerId(this.selectedEmailRequest.brokerId).subscribe({
				next: x =>
				{
					this.erpConnectionDataSource = x;
					this.erpConnectionBrokerId = this.selectedEmailRequest.brokerId;
				},
				error: (err: HttpErrorResponse) =>
				{
					if (err.error instanceof ErrorEvent) {
						// Client-side or network error
						this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
					} 
					else {
						// Backend error
						switch (err.status) {
							case 400:
								this.openSnackBar("Error fetching ERP Connection data: 400 Bad Request.", "Dismiss");
								break;
							case 401:
								this.openSnackBar("Error fetching ERP Connection data: 401 Unauthorized.", "Dismiss");
								break;
							case 404:
								this.openSnackBar("Error fetching ERP Connection data: 404 Not Found.", "Dismiss");
								break;
							case 500:
								this.openSnackBar("Error fetching ERP Connection data: 500 Server Error", "Dismiss");
								break;
							default:
								this.openSnackBar("Error fetching ERP Connection data: Unknown Error", "Dismiss");
								break;
						}
					}

					console.log(err);
				}
			});
		}

		this.erpSubmissionAttemptDataSource = [];
		this.parsedEmailDataSource = [];
		this.selectedParsedEmail = this.makeParsedEmailObj();
		this.refreshParsedEmailsForEmailRequestId(emailRequest.id);
	}

	parsedEmailClick(parsedEmail: ParsedEmail)
	{
		this.erpSubmissionAttemptDataSource = [];
		this.selectedParsedEmail = parsedEmail;
		this.refreshERPSubmissionAttemptsForParsedEmailId(parsedEmail.id);
	}

	saveNewEmail(drawer: any)
	{

		this.resetCustomerFilter();
		this.resetAddressBookFilter();
		if(this.selectedCustomer < 0)
		{
			this.openSnackBar("A valid customer must be chosen before saving a new Email Request.", "Dismiss");
			return;
		}
		
		let newItem = this.makeEmailRequestObj();
		newItem.emailSender = this.txtSender;
		newItem.emailRecipient = this.txtRecipient;
		newItem.emailSubject = this.txtSubject;
		newItem.emailBody = this.txtBody;
		newItem.brokerId = this.selectedBroker;
		newItem.eRPConnectionCustomerId = this.selectedCustomer;

		if(this.currentExternalEmail != null)
		{
			newItem.externalEmailId = this.currentExternalEmail.id;
		}

		this.isSavingNewEmail = true;

		if(this.chosenFile == null || this.chosenFileName == "")
		{
			if(this.currentExternalEmail != null && this.currentExternalEmail?.hasAttachments)
			{
				// call the endpoint for external email attachments
				this.emailRequestDataService.createEmailRequestWithExternalEmailAttachment(newItem).subscribe({
					next: x => 
					{
						this.openSnackBar("Email Request created successfully!", "Dismiss");
						this.txtSender = "";
						this.txtRecipient = "";
						this.txtSubject = "";
						this.txtBody = "";
						this.selectedCustomer = -1;
						this.isSavingNewEmail = false;
						this.chosenFileName = "";
						this.chosenFile = null;
						this.inboxMode = "turbotl";
						this.currentExternalEmail = null;
						this.refreshItems();

						drawer.close();
					},
					error: (err: HttpErrorResponse) =>
					{
						this.isSavingNewEmail = false;
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error creating Email Request: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error creating Email Request: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error creating Email Request: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error creating Email Request: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error creating Email Request: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});
			}
			else
			{
				// create an email request supposing that the user has NOT included an attached doc
				this.emailRequestDataService.createEmailRequest(newItem).subscribe({
					next: x => 
					{
						this.openSnackBar("Email Request created successfully!", "Dismiss");
						this.txtSender = "";
						this.txtRecipient = "";
						this.txtSubject = "";
						this.txtBody = "";
						this.selectedCustomer = -1;
						this.isSavingNewEmail = false;
						this.chosenFileName = "";
						this.chosenFile = null;
						this.inboxMode = "turbotl";
						this.currentExternalEmail = null;
						this.refreshItems();

						drawer.close();
					},
					error: (err: HttpErrorResponse) =>
					{
						this.isSavingNewEmail = false;
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error creating Email Request: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error creating Email Request: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error creating Email Request: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error creating Email Request: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error creating Email Request: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});
			}
		}
		else
		{
			const formData = new FormData();
			// Convert the JSON object to a string and append it to FormData
			formData.append('req', JSON.stringify(newItem));

			// Append file to the FormData object
			formData.append('file', this.chosenFile, this.chosenFile.name);

			// create an email request supposing that the user has included an attached doc 
			this.emailRequestDataService.createEmailRequestWithAttachment(formData).subscribe({
				next: x => 
				{
					this.openSnackBar("Email Request created successfully!", "Dismiss");
					this.txtSender = "";
					this.txtRecipient = "";
					this.txtSubject = "";
					this.txtBody = "";
					this.selectedCustomer = -1;
					this.isSavingNewEmail = false;
					this.chosenFileName = "";
					this.chosenFile = null;
					this.inboxMode = "turbotl";
					this.currentExternalEmail = null;
					this.refreshItems();

					drawer.close();
				},
				error: (err: HttpErrorResponse) =>
				{
					this.isSavingNewEmail = false;
					if (err.error instanceof ErrorEvent) {
						// Client-side or network error
						this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
					} 
					else {
						// Backend error
						switch (err.status) {
							case 400:
								console.log(err);
								this.openSnackBar("Error creating Email Request: 400 Bad Request.", "Dismiss");
								break;
							case 401:
								this.openSnackBar("Error creating Email Request: 401 Unauthorized.", "Dismiss");
								break;
							case 404:
								this.openSnackBar("Error creating Email Request: 404 Not Found.", "Dismiss");
								break;
							case 500:
								this.openSnackBar("Error creating Email Request: 500 Server Error", "Dismiss");
								break;
							default:
								this.openSnackBar("Error creating Email Request: Unknown Error", "Dismiss");
								break;
						}
					}
					console.log(err);
				}
			});
		}
	}

	createSubmission(connId: number, ERPstatus: number)
	{
		// here, ERPstatus may be 0 = quoted or 1 = booked
		this.erpSubmissionAttemptDataService.createERPSubmissionAttempt(this.selectedParsedEmail.id, connId, ERPstatus).subscribe({
			next: x =>
			{
				this.openSnackBar("ERP Submission Attempt created.  Id: " + x.id, "Dismiss");
				this.refreshERPSubmissionAttemptsForParsedEmailId(this.selectedParsedEmail.id);
				this.submitToERP(x.id); // we got confirmation that the submission was successfully created, now let's submit it
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating ERP Submission Attempt: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating ERP Submission Attempt: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating ERP Submission Attempt: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating ERP Submission Attempt: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating ERP Submission Attempt: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	submitToERP(erpSubmissionId: number)
	{
		this.erpSubmissionAttemptDataService.submitToERP(erpSubmissionId).subscribe({
			next: x =>
			{
				// in the returned object, there is a status integer field.
				// 0 = Not Submitted
				// 1 = Submitted
				// 2 = Successful (presumably created correctly in ERP)
				// 3 = Unsuccessful (something failed creating in ERP)
				if(x.status == 0)
				{
					this.openSnackBar("Error occurred: data was not submitted to the desired ERP.  Id: " + x.id, "Dismiss");
				}
				else if(x.status == 1)
				{
					this.openSnackBar("Error occurred: data was sent to the desired ERP, but there was no reply.  Id: " + x.id, "Dismiss");
				}
				else if(x.status == 2)
				{
					this.openSnackBar("Success!  Data was created in the chosen ERP successfully!  Id: " + x.id, "Dismiss");
					this.updateEmailRequestWithSubmissionId(erpSubmissionId);
				}
				else if(x.status == 3)
				{
					this.openSnackBar("Error occurred: data was sent to the desired ERP, but the ERP reported a problem.  Id: " + x.id, "Dismiss");
				}
				this.refreshERPSubmissionAttemptsForParsedEmailId(x.parsedEmailId);

			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error submitting ERP Submission Attempt: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error submitting ERP Submission Attempt: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error submitting ERP Submission Attempt: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error submitting ERP Submission Attempt: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error submitting ERP Submission Attempt: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	updateEmailRequestWithSubmissionId(erpSubmissionId: number)
	{
		this.erpSubmissionAttemptDataService.getEmailRequestForERPSubmissionAttemptId(erpSubmissionId).subscribe({
			next: x =>
			{
				let filtered = this.emailRequestDataSource.find((item: EmailRequest) => item.id == x.id);
				if(filtered)
				{
					// looks like we found a local version of the object and can update it
					filtered.mostRecentSuccessfulERPSubmissionId = erpSubmissionId;
				}
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error submitting ERP Submission Attempt: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error submitting ERP Submission Attempt: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error submitting ERP Submission Attempt: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error submitting ERP Submission Attempt: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error submitting ERP Submission Attempt: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});


	}

	createParsedEmail(item: EmailRequest)
	{
		this.parsedEmailDataService.createParsedEmail(item.id).subscribe({
			next: x =>
			{
				this.openSnackBar("AI Email Parsing created.  Id: " + x.id, "Dismiss");
				this.refreshParsedEmailsForEmailRequestId(item.id);
				this.submitToAI(x.id); // we got confirmation that the submission was successfully created, now let's submit it to the AI
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error creating Parsed Email: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error creating Parsed Email: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error creating Parsed Email: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error creating Parsed Email: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error creating Parsed Email: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	submitToAI(parsedEmailId: number)
	{
		this.parsedEmailDataService.submitParsedEmailToAI(parsedEmailId).subscribe({
			next: x =>
			{
				// in the returned object, there is a status integer field.
				// 0 = Not Submitted
				// 1 = Submitted
				// 2 = Successful (presumably created correctly in ERP)
				// 3 = Unsuccessful (something failed creating in ERP)
				if(x.status == 0)
				{
					this.openSnackBar("Error occurred: submitting email data to AI failed.  Id: " + x.id, "Dismiss");
				}
				else if(x.status == 1)
				{
					this.openSnackBar("Error occurred: email data was submitted to AI, but there was no reply.  Id: " + x.id, "Dismiss");
				}
				else if(x.status == 2)
				{
					this.openSnackBar("Success!  Email data was successfully parsed by AI!  Id: " + x.id, "Dismiss");
				}
				else if(x.status == 3)
				{
					this.openSnackBar("Error occurred: email data was submitted to AI, but an error was reported.  Id: " + x.id, "Dismiss");
				}
				this.refreshParsedEmailsForEmailRequestId(x.emailRequestId);
				this.selectedEmailRequest = this.emailRequestDataSource.find(y => y.id == x.emailRequestId);
			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error submitting Parsed Email to AI: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error submitting Parsed Email to AI: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error submitting Parsed Email to AI: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error submitting Parsed Email to AI: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error submitting Parsed Email to AI: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	importExternalEmail(item: ExternalEmail, drawer: any)
	{
		// the 'true' in this call is telling the service to calculate the "implied" sender of the email as discovered in the headers
		this.externalEmailDataService.getExternalEmail(item.id, true).subscribe({
			next: x =>
			{
				if(this.erpConnectionDataSource == null)
				{
					this.openSnackBar("No ERP Connections detected.  Configure ERP Connection and set as default to proceed.", "Dismiss");
					return;
				}
				const s = this.erpConnectionDataSource.find(x => x.isDefault == true);
				this.selectedERPConnection = (s == null ? -1 : s.id);

				this.currentExternalEmail = x;
				this.flyoutmode = 0;

				this.txtSender = x.senderEmailAddress;
				this.txtRecipient = "";
				this.txtSubject = x.subject;
				this.txtBody = x.body;

				// try to figure out customer based on email domain
				const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

				if (emailPattern.test(this.txtSender)) {
					const parts = this.txtSender.split('@');
					const domain = parts[1];
					const capitalizedDomain = domain.toUpperCase();

					if(this.erpConnectionCustomerDataSource == null)
					{
						this.openSnackBar("No Customer data source detected.  Refresh Customer List from ERP Connection and try again.", "Dismiss");
						return;
					}
					let filtered = this.erpConnectionCustomerDataSource.filter(x => x.emailDomain.toUpperCase() === capitalizedDomain);

					if(filtered.length > 0)
					{
						this.resetCustomerFilter();
						this.selectedCustomer = filtered[0].id;
					}
				
				}


				drawer.open();

			},
			error: (err: HttpErrorResponse) =>
			{
				if (err.error instanceof ErrorEvent) {
					// Client-side or network error
					this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
				} 
				else {
					// Backend error
					switch (err.status) {
						case 400:
							this.openSnackBar("Error retrieving email body: 400 Bad Request.", "Dismiss");
							break;
						case 401:
							this.openSnackBar("Error retrieving email body: 401 Unauthorized.", "Dismiss");
							break;
						case 404:
							this.openSnackBar("Error retrieving email body: 404 Not Found.", "Dismiss");
							break;
						case 500:
							this.openSnackBar("Error retrieving email body: 500 Server Error", "Dismiss");
							break;
						default:
							this.openSnackBar("Error retrieving email body: Unknown Error", "Dismiss");
							break;
					}
				}

				console.log(err);
			}
		});
	}

	dismissExternalEmail(item: ExternalEmail)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
				this.externalEmailDataService.dismissExternalEmail(item.id).subscribe({
					next: x => 
					{
						this.openSnackBar("External Email dismissed.  Id: " + item.id, "Dismiss");
						this.refreshItems();	
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error dismissing External Email: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error dismissing External Email: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error dismissing External Email: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error dismissing External Email: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error dismissing External Email: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});


			}
			else
			{
				// no, do not delete
			}
		});

	}

	dismissEmail(item: EmailRequest)
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				// yes, go ahead and delete
				this.emailRequestDataService.dismissEmailRequest(item.id).subscribe({
					next: x => 
					{
						this.openSnackBar("Email Request dismissed.  Id: " + item.id, "Dismiss");
						this.erpSubmissionAttemptDataSource = [];
						this.parsedEmailDataSource = [];
						this.selectedParsedEmail = this.makeParsedEmailObj();
						this.refreshItems();	
					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error dismissing Email Request: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error dismissing Email Request: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error dismissing Email Request: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error dismissing Email Request: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error dismissing Email Request: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});


			}
			else
			{
				// no, do not delete
			}
		});


	}

	// create a "blank" instance of the ParsedEmail interface/obj
	makeParsedEmailObj() : ParsedEmail
	{
		let newItem: ParsedEmail =
		{
			id: 0,
			aIEngineUsed: 0,
			createDate: new Date(),
			orderObject: this.makeOrderObj(),
			status: 0,
			errorNotes: null,
			emailRequestId: 0,
			userId: 0,
			brokerId: 0,
			erpConnectionCustomerId: 0,
			isDismissed: false
		};
		return newItem;
	}

	makeOrderObj(): Order
	{
		let newItem: Order =
		{
			id: 0,
			gptQuestions: []
		};
		return newItem;
	}

	makeExternalEmailObj() : ExternalEmail
	{
		let newItem: ExternalEmail =
		{
			id: 0,
			emailId: '',
			receivedDateTime: new Date(),
			senderEmailAddress: '',
			subject: '',
			body: '',
			bodyPreview: '',
			brokerId: 0,
			emailRequestId: 0,
			hasAttachments: false,
			isDismissed: false,
			isConvertedToEmailRequest: false
		};
		return newItem;
	}

	makeEmailRequestObj() : EmailRequest 
	{
		let newItem: EmailRequest =
		{
			id: 0,
			createDate: new Date(),
			emailSender: '',
			emailRecipient: '',
			emailSubject: '',
			emailBody: '',
			brokerId: 0,
			userId: 0,	
			isDismissed: false,
			eRPSubmissionAttemptId: 0,
			eRPConnectionCustomerId: 0,
			externalEmailId: 0
		};
		return newItem;
	}

	makeOrderItemObj(oID: number) : OrderItem
	{
		let newItem: OrderItem =
		{
			id: 0,
			orderId: oID,
			length: null,
			width: null,
			height: null,
			dimensionUnits: null,
			cargoClass: null,
			pieceCount: null,
			productDescription: null,
			weight: null,
			weightUnits: null,
			nmfcNumber: null,
			pcf: null
		};
		return newItem;
	}

	replyEmail()
	{
		console.log(this.selectedParsedEmailDetailed.orderObject.gptQuestions);
		let questions = this.selectedParsedEmailDetailed.orderObject == null ? "" : this.selectedParsedEmailDetailed.orderObject.gptQuestions;
		let encodedRecipient = encodeURIComponent(this.selectedEmailRequest.emailSender);
		let encodedSubject = encodeURIComponent("RE: " + this.selectedEmailRequest.emailSubject);
		let encodedBody = encodeURIComponent("Hi, \n\nThanks for your request, but we need a bit more information before we can proceed.  Specifically: \n\n" + questions + "\n\nOriginal message: \n\n" + this.selectedEmailRequest.emailBody).replace(/%0A/g, '%0D%0A');
		
	    const mailtoLink = `mailto:${encodedRecipient}?subject=${encodedSubject}&body=${encodedBody}`;
		console.log(mailtoLink);
		window.location.href = mailtoLink;
	}	

	onFileDragEnter(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		element.classList.add('drag-over');
	}

	onFileDragOver(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		if(!element.classList.contains('drag-over'))
		{
			element.classList.add('drag-over');
		}
	}

	onFileDragLeave(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		element.classList.remove('drag-over');
	}

	onFileDrop(event: DragEvent)
	{
		event.preventDefault();
		if (event.dataTransfer?.files?.length) {
			this.chosenFileName = event.dataTransfer.files[0].name;
			this.chosenFile = event.dataTransfer.files[0];
		}
		const element = event.currentTarget as HTMLElement;
		element.classList.remove('drag-over');
	}

	onDragOver(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		if(!element.classList.contains('drag-over'))
		{
			element.classList.add('drag-over');
		}
	}

	onDragLeave(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		element.classList.remove('drag-over');
	}

	onDragEnter(event: DragEvent)
	{
		event.preventDefault();

		const element = event.currentTarget as HTMLElement;
		element.classList.add('drag-over');
	}

	onDrop(event: DragEvent, drawer: any)
	{
		this.flyoutmode = 0;
		this.currentExternalEmail = null;
		event.preventDefault();
//		event.stopPropagation();
		const s = this.erpConnectionDataSource.find(x => x.isDefault == true);
		this.selectedERPConnection = (s == null ? -1 : s.id);

		const element = event.currentTarget as HTMLElement;
		element.classList.remove('drag-over');
		this.selectedCustomer = -1;

		this.ngZone.runOutsideAngular(() => {
	//		const file = event.dataTransfer?.files[0];
			console.log(event);
			console.log(event.dataTransfer);
			try
			{
				if(event.dataTransfer.items)
				{
					Array.from(event.dataTransfer.items).forEach((item) =>
					{
						console.log("FOUND AN ITEM!");
						console.log("KIND OF FILE: " + item.kind);
						if(item.kind == "file") {
							console.log("FOUND A FILE!");
							console.log(item.getAsFile());
	//						this.readFile(item.getAsFile(), drawer);
							this.loadFile(item.getAsFile(), drawer);
						}
						else if(item.kind == "string") {
							console.log("HERE IS THE CONTENTS OF THE STRING");
							console.log(item);
						}
					});
				}
				else
				{
					console.log("FILE COULD NOT BE LOADED CORRECTLY");
					this.openSnackBar("Error: File could not be loaded correctly.", "Dismiss");
				}
			}
			catch(error)
			{
				console.log("ERROR IN DROP EVENT");
				console.log(error);
				this.openSnackBar("Error: Dragged file must be of type MSG or EML.", "Dismiss");
			}
		});
	}

	newEmail(drawer: any) {
		const s = this.erpConnectionDataSource.find(x => x.isDefault == true);
		this.selectedERPConnection = (s == null ? -1 : s.id);

		this.currentExternalEmail = null;
		this.flyoutmode = 0;
		this.selectedCustomer = -1;
		this.txtSender = "";
		this.txtRecipient = "";
		this.txtSubject = "";
		this.txtBody = "";	
		drawer.open();
	}

	reverseShipperConsignee()
	{
		let shipperBusinessName = this.selectedParsedEmailDetailed.orderObject.shipperBusinessName;
		let shipperAddress = this.selectedParsedEmailDetailed.orderObject.shipperAddress;
		let shipperCity = this.selectedParsedEmailDetailed.orderObject.shipperCity;
		let shipperState = this.selectedParsedEmailDetailed.orderObject.shipperState;
		let shipperZip = this.selectedParsedEmailDetailed.orderObject.shipperZip;
		let shipperPhone = this.selectedParsedEmailDetailed.orderObject.shipperPhone;
		let shipperName = this.selectedParsedEmailDetailed.orderObject.shipperName;
		let shipperEmail = this.selectedParsedEmailDetailed.orderObject.shipperEmail;

		this.selectedParsedEmailDetailed.orderObject.shipperBusinessName = this.selectedParsedEmailDetailed.orderObject.consigneeBusinessName;
		this.selectedParsedEmailDetailed.orderObject.shipperAddress = this.selectedParsedEmailDetailed.orderObject.consigneeAddress;
		this.selectedParsedEmailDetailed.orderObject.shipperCity = this.selectedParsedEmailDetailed.orderObject.consigneeCity;
		this.selectedParsedEmailDetailed.orderObject.shipperState = this.selectedParsedEmailDetailed.orderObject.consigneeState;
		this.selectedParsedEmailDetailed.orderObject.shipperZip = this.selectedParsedEmailDetailed.orderObject.consigneeZip;
		this.selectedParsedEmailDetailed.orderObject.shipperPhone = this.selectedParsedEmailDetailed.orderObject.consigneePhone;
		this.selectedParsedEmailDetailed.orderObject.shipperName = this.selectedParsedEmailDetailed.orderObject.consigneeName;
		this.selectedParsedEmailDetailed.orderObject.shipperEmail = this.selectedParsedEmailDetailed.orderObject.consigneeEmail;

		this.selectedParsedEmailDetailed.orderObject.consigneeBusinessName = shipperBusinessName;
		this.selectedParsedEmailDetailed.orderObject.consigneeAddress = shipperAddress;
		this.selectedParsedEmailDetailed.orderObject.consigneeCity = shipperCity;
		this.selectedParsedEmailDetailed.orderObject.consigneeState = shipperState;
		this.selectedParsedEmailDetailed.orderObject.consigneeZip = shipperZip;
	 	this.selectedParsedEmailDetailed.orderObject.consigneePhone = shipperPhone;
		this.selectedParsedEmailDetailed.orderObject.consigneeName = shipperName;
		this.selectedParsedEmailDetailed.orderObject.consigneeEmail = shipperEmail;
	}

	convertToInches(measure: number, units: string)
	{
		if(units.toUpperCase() == "IN")
		{
			return measure;
		}
		else if(units.toUpperCase() == "MM")
		{
			return measure * 0.03937;
		}
		else if(units.toUpperCase() == "CM")
		{
			return measure * 0.3937;
		}
		else if(units.toUpperCase() == "M")
		{
			return measure * 39.37;
		}
		else if(units.toUpperCase() == "FT")
		{
			return measure * 12;
		}
		else if(units.toUpperCase() == "YD")
		{
			return measure * 36;
		}
		else
		{
			return 0;
		}
	}

	convertToPounds(measure: number, units: string)
	{
		if(units.toUpperCase() == "LB")
		{
			return measure;
		}
		else if(units.toUpperCase() == "MG")
		{
			return measure * 2.2046e-6;
		}
		else if(units.toUpperCase() == "G")
		{
			return measure * 0.0022046;
		}
		else if(units.toUpperCase() == "KG")
		{
			return measure * 2.2046;
		}
		else if(units.toUpperCase() == "MT")
		{
			return measure * 2204.6;
		}
		else if(units.toUpperCase() == "OZ")
		{
			return measure * 0.0625;
		}
		else if(units.toUpperCase() == "T")
		{
			return measure * 2000;
		}
		else
		{
			return 0;
		}
	}

	calculatePCF()
	{
		const weightLb = this.convertToPounds(this.PCFWeight, this.selectedPCFWeightUnit);
		const lengthIn = this.convertToInches(this.PCFLength, this.selectedPCFLengthUnit);
		const widthIn = this.convertToInches(this.PCFWidth, this.selectedPCFWidthUnit);
		const heightIn = this.convertToInches(this.PCFHeight, this.selectedPCFHeightUnit);

		const numerator = weightLb / this.PCFPieceCount;
		const denominator = ((lengthIn * widthIn * heightIn) / 1728.0);

		this.calculatedPCF = Math.round((numerator / denominator) * 1000) / 1000;
	}

	applyPCFToOrder()
	{
		this.selectedOrderItem.pcf = this.calculatedPCF;
	}

	togglePCFCalculator(event: MouseEvent)
	{
		const divWidth = 400;
		const divHeight = 600;
		if(!this.showPCF)
		{
			this.PCFHeight = this.selectedOrderItem.height;
			this.PCFWidth = this.selectedOrderItem.width;
			this.PCFLength = this.selectedOrderItem.length;
			this.selectedPCFHeightUnit = this.selectedOrderItem.dimensionUnits;
			this.selectedPCFWidthUnit = this.selectedOrderItem.dimensionUnits;
			this.selectedPCFLengthUnit = this.selectedOrderItem.dimensionUnits;
			this.PCFWeight = this.selectedOrderItem.weight;
			this.selectedPCFWeightUnit = this.selectedOrderItem.weightUnits;
			this.PCFPieceCount = this.selectedOrderItem.pieceCount;
		}
		this.showPCF = !this.showPCF;	
		this.mouseX = Math.min(window.innerWidth - divWidth, event.clientX);
		this.mouseY = Math.min(window.innerHeight - divHeight, event.clientY); 
		this.calculatedPCF = 0;
	}

// NEW CODE
	loadFile(file, drawer: any) {

		this.txtSender = "";
		this.txtRecipient = "";
		this.txtSubject = "";
		this.txtBody = "";

		let fileType = "";

		let results = file.name.split('.');
		if(results[results.length - 1].toUpperCase() == "MSG") {
			fileType = "MSG";
		}
		else if (results[results.length - 1].toUpperCase() == "EML") {
			fileType = "EML";
		}
		else {
			this.openSnackBar("Error: The email file type must be either MSG or EML.", "Dismiss");
		}

		this.ngZone.runOutsideAngular(() => {

			const reader = new FileReader();


			const getContent = (mimenode: MimeNode): string[] => {
				const emailContent: string[] = [];
				const _getContent = (childNodes: MimeNode[]) => {
					childNodes.forEach(child => {
						if(child.childNodes.length) {
							_getContent(child.childNodes);
						} else if (child.content && child.charset === 'utf-8' && child.headers['content-type'][0].value === 'text/html') {
							emailContent.push(new TextDecoder().decode(child.content));
						} else if (child.content && child.headers['content-type'][0].value === 'text/plain') {
							emailContent.push(new TextDecoder().decode(child.content));
						} else if (child.content && child.headers['content-type'][0].value === 'application/pdf') {

							// Attempt to extract the filename from the Content-Disposition header
							let contentDisposition = child.headers['content-disposition']?.[0]?.params?.filename;
							if (contentDisposition) {
								this.chosenFileName = contentDisposition;  // Set the filename if found
							} else {
								this.chosenFileName = "Attached.pdf";  // Fallback filename if none found
							}

							const blob = new Blob([child.content], { type: 'application/pdf' });
							this.chosenFile = new File([blob], this.chosenFileName, { type: 'application/pdf', lastModified: Date.now() });
						} else {
							// not sure what to do if other type...
						}	
					});
				}
				_getContent([mimenode]);
				return emailContent;
			}

			if(fileType == "EML") {
				reader.addEventListener("load", () => {
					const parsed = parse(reader.result);
					console.log("parsed email: ", parsed);
					this.list = [{
						...parsed.headers,
						body: getContent(parsed).join()
					}, ...this.list];

					try
					{
						this.txtSender = this.list[0]["from"][0].value[0]["address"];
						this.txtRecipient = this.list[0]["to"][0].value[0]["address"];
						this.txtSubject = this.list[0]["subject"][0].value;
						this.txtBody = this.list[0]["body"];

						// try to figure out customer based on email domain
						const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

						if (emailPattern.test(this.txtSender)) {
							const parts = this.txtSender.split('@');
							const domain = parts[1];
							const capitalizedDomain = domain.toUpperCase();

							let filtered = this.erpConnectionCustomerDataSource.filter(x => x.emailDomain.toUpperCase() === capitalizedDomain);

							if(filtered.length > 0)
							{
								this.resetCustomerFilter();
								this.selectedCustomer = filtered[0].id;
							}
						
						}

					}
					catch(error)
					{
						console.log(error);
					}

				}, false);

				reader.readAsText(file);
			}

			if(fileType == "MSG") {
				reader.addEventListener("load", (e: ProgressEvent): void => {
					console.log("File loaded");
					let bytes = new Uint8Array((<any>e.target).result);

					const msgReader = new MsgReader.MSGReader(bytes);
					const msg = msgReader.getFileData();
					console.log(msg);

					this.txtSender = (msg as any).senderEmail;
					this.txtRecipient = "";
					for(let i of (msg as any).recipients)
					{
						this.txtRecipient += i.email + ",";
					}
					this.txtRecipient = this.txtRecipient.slice(0, -1);
					this.txtSubject = (msg as any).subject;
					this.txtBody = (msg as any).body;

					if((msg as any).attachments)
					{
						for(let i = 0; i < (msg as any).attachments.length; i++)
						{
							try
							{
								let attachment = msgReader.getAttachment((msg as any).attachments[i]);
								if (attachment.content && attachment.fileName.toUpperCase().endsWith(".PDF")) {
									this.chosenFileName = attachment.fileName;
									// If the attachment is a PDF or similar, create a Blob to download it
									const blob = new Blob([attachment.content], { type: 'application/pdf'});
									this.chosenFile = new File([blob], this.chosenFileName, { type: 'application/pdf', lastModified: Date.now() });
									break;
								}
							}
							catch(error)
							{
								console.log(error);
							}
						}
					}

					// try to figure out customer based on email domain
					const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

					if (emailPattern.test(this.txtSender)) {
						const parts = this.txtSender.split('@');
						const domain = parts[1];
						const capitalizedDomain = domain.toUpperCase();

						let filtered = this.erpConnectionCustomerDataSource.filter(x => x.emailDomain.toUpperCase() === capitalizedDomain);

						if(filtered.length > 0)
						{
							this.resetCustomerFilter();
							this.selectedCustomer = filtered[0].id;
						}
					
					}

					if (msg['error']) { console.log(msg["error"]); }

				}, false);

				reader.readAsArrayBuffer(file);
			}
		});

		drawer.open();

	}

	removeFile()
	{
		this.chosenFile = null;
		this.chosenFileName = "";
		this.openSnackBar("Attached file removed from email.", "Dismiss");
	}

	ignoreInboxAttachment()
	{
		if(this.currentExternalEmail != null)
		{
			this.currentExternalEmail.hasAttachments = false;
		}
	}
// END OF NEW CODE

	addOrderItem()
	{
		let orderId = this.selectedParsedEmailDetailed.orderObject.id;
		this.selectedParsedEmailDetailed.orderObject.orderItems.push(this.makeOrderItemObj(orderId));
		this.openSnackBar("New Cargo Item added to this order!", "Dismiss");
	}

	deleteOrderItem()
	{
		this.selectedParsedEmailDetailed.orderObject.orderItems = this.selectedParsedEmailDetailed.orderObject.orderItems.filter(x => x.id !== this.selectedOrderItem.id);
		this.openSnackBar("Selected Cargo Item deleted from this order!", "Dismiss");
		this.selectedOrderItem = null;
	}


	readFile(file: File, drawer: any)
	{
		console.log("READ FILE");
		try
		{
			this.ngZone.runOutsideAngular(() => {
				const reader = new FileReader();
				reader.onload = (e: any) => {
					console.log("FILEREADER ON_LOAD FIRED");
					const emlContent = e.target.result;
					this.parseEML(emlContent, drawer);
				};
				reader.onerror = (err: any) => {
					console.log("FILEREADER ON_ERROR FIRED");
					console.log(err);
				};
				reader.onloadend = (done: any) => {
					console.log("FILEREADER LOAD_END FIRED");
					console.log(done);
				};
				console.log("ABOUT TO READ AS TEXT");
				reader.readAsText(file);
			});
		}
		catch(error)
		{
			console.log("ERROR IN READFILE");
			console.log(file);
			console.log(error);
			this.openSnackBar("Error: There was an error parsing the email message.", "Dismiss");
		}
	}

	extractEmailAddress(inputString) {
		const emailPattern = /<([^>]+)>/;
		const match = inputString.match(emailPattern);
		return match ? match[1] : null;
	}

	stripEmailHeaders(body) {
		const headerSeparator = '\n\n';
		const indexOfBody = body.indexOf(headerSeparator);

		if (indexOfBody === -1) {
		// If no header separator is found, return the original email string
		return body;
		}

		// Extract and return the body part of the email
		return body.substring(indexOfBody + headerSeparator.length);
	}

	parseEML(emlText: string, drawer: any)
	{
		console.log("PARSE EML");
		try
		{
			const data = parse(emlText);
			console.log(data.childNodes[0].childNodes[0]);
			data.header.forEach((x) => {
				if(x.startsWith("To:")) {
					this.txtRecipient = this.extractEmailAddress(x);
				}
				else if(x.startsWith("From:")) {
					this.txtSender = this.extractEmailAddress(x);
				}
				else if(x.startsWith("Subject:")) {
					this.txtSubject = x.substring(9);
				}
			});

			if(data.childNodes[0] != null && data.childNodes[0].childNodes[0] != null) {
				this.txtBody = this.stripEmailHeaders(data.childNodes[0].childNodes[0].raw);
			}
			else if (data.childNodes[0] != null) {
				this.txtBody = this.stripEmailHeaders(data.childNodes[0].raw);
			}

			// try to figure out customer based on email domain
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

			if (emailPattern.test(this.txtSender)) {
				const parts = this.txtSender.split('@');
				const domain = parts[1];
				const capitalizedDomain = domain.toUpperCase();

				let filtered = this.erpConnectionCustomerDataSource.filter(x => x.emailDomain.toUpperCase() === capitalizedDomain);

				if(filtered.length > 0)
				{
					this.selectedCustomer = filtered[0].id;
				}
			
			}
		}
		catch(error)
		{
			console.log("Error in ParseEML");
			console.log(error);
			this.openSnackBar("Error: Parse process failed.", "Dismiss");
		}	

		this.flyoutmode = 0;
		drawer.open();
	}

	getDomainFromEmail(email: string)
	{
		const parts = email.split('@');

		if (parts.length !== 2) {
			return "";
		}

		return parts[1].toUpperCase();
	}

	associateDomain()
	{
		var conf = this.yesnoConfirm.open(YesnoconfirmComponent);
		conf.afterDismissed().subscribe(data =>
		{
			if(data)
			{
				let erpConnCust = this.erpConnectionCustomerDataSource.filter(x => x.id == this.selectedCustomer)[0];
				let emailDomain = this.getDomainFromEmail(this.txtSender);
				erpConnCust.emailDomain = emailDomain; // update the object we're going to send to the server
				this.erpConnectionCustomerDataService.updateEmailDomain(erpConnCust).subscribe({
					next: x =>
					{
						this.openSnackBar("Email domain successfully associated with chosen customer", "Dismiss");
						this.erpConnectionCustomerDataService.getERPConnectionCustomersByBrokerId(this.selectedBroker).subscribe({
							next: x => 
							{
								this.erpConnectionCustomerDataSource = x;
							},
							error: (err: HttpErrorResponse) =>
							{
								if (err.error instanceof ErrorEvent) {
									// Client-side or network error
									this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
								} 
								else {
									// Backend error
									switch (err.status) {
										case 400:
											this.openSnackBar("Error fetching ERP Connection Customer data: 400 Bad Request.", "Dismiss");
											break;
										case 401:
											this.openSnackBar("Error fetching ERP Connection Customer data: 401 Unauthorized.", "Dismiss");
											break;
										case 404:
											this.openSnackBar("Error fetching ERP Connection Customer data: 404 Not Found.", "Dismiss");
											break;
										case 500:
											this.openSnackBar("Error fetching ERP Connection Customer data: 500 Server Error", "Dismiss");
											break;
										default:
											this.openSnackBar("Error fetching ERP Connection Customer data: Unknown Error", "Dismiss");
											break;
									}
								}
								console.log(err);
							}
						});


					},
					error: (err: HttpErrorResponse) =>
					{
						if (err.error instanceof ErrorEvent) {
							// Client-side or network error
							this.openSnackBar("A client error occurred: " + err.error.message, "Dismiss");
						} 
						else {
							// Backend error
							switch (err.status) {
								case 400:
									this.openSnackBar("Error associating domain with customer: 400 Bad Request.", "Dismiss");
									break;
								case 401:
									this.openSnackBar("Error associating domain with customer: 401 Unauthorized.", "Dismiss");
									break;
								case 404:
									this.openSnackBar("Error associating domain with customer: 404 Not Found.", "Dismiss");
									break;
								case 500:
									this.openSnackBar("Error associating domain with customer: 500 Server Error", "Dismiss");
									break;
								default:
									this.openSnackBar("Error associating domain with customer: Unknown Error", "Dismiss");
									break;
							}
						}
						console.log(err);
					}
				});
			}
		});
	}

	mylog(st)
	{
		console.log(st);
	}

}
